import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
	className?: string;
}
const ChevronCircleTick = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 11 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g id="chevron-circle-tick">
			<path id="Vector 2" d="M1 3.34211L4.09677 6.5L9 1.5" stroke="white" />
		</g>
	</svg>
);
export default ChevronCircleTick;
