'use client';
import { Fragment, useTransition } from 'react';
import { useCart } from '../../_providers/Cart';
import { useLocale, useTranslations } from 'next-intl';
import { formatPrice } from '../../_utilities/format-price';
import { Button } from '../../_components/Button';
import { useRouter } from '../../../navigation';
import { Skeleton } from '@shadcn/components/ui/skeleton';
import { useAuth } from '../../_providers/Auth';
import { AuthModal } from '../AuthModal';
import dayjs from 'dayjs';
import { DiscountCodeForm } from '../discount-code-form';
import { Flatten } from '../../_utilities/typescript';
import { Courseversion, OrderCartItems } from '../../../payload/payload-types';
import { CartItem } from '../../_providers/Cart/reducer';
import { useTrackEvent } from '../../_providers/TrackEventProvider';

export const CartTotal: React.FC = () => {
	const t = useTranslations('Cart');
	const { cart, cartTotal, cartTotalVat, cartHasVat, isCartSyncInProgress } = useCart();

	return (
		<div>
			<div className="flex items-center justify-between space-x-8">
				<p className="text-sm font-bold uppercase tracking-widest text-gold">
					{t('total')}
				</p>
				<hr className="h-[1px] w-max grow border-0 bg-gold-light opacity-20" />

				<p className="text-xl font-bold text-muted-navy">{cartTotal.formatted}</p>
			</div>

			<p className="mt-0 text-sm font-medium leading-6">
				{cartHasVat
					? t('purchase-includes-vat', { totalVat: cartTotalVat.formatted })
					: t('purchase-does-not-include-vat')}
			</p>
		</div>
	);
};

const DiscountedRow: React.FC<{
	courseversion: Courseversion;
	cartItem: CartItem;
}> = ({ courseversion, cartItem }) => {
	const locale = useLocale();
	const t = useTranslations('Cart');
	const { cart } = useCart();
	const hasCourseversionDiscount =
		courseversion.discountStatus === 'enabled' && Boolean(courseversion.discountName);
	const hasDiscountCodeDiscount = Boolean(cartItem.discountCodeApplied);

	if (!hasCourseversionDiscount && !hasDiscountCodeDiscount) {
		return null;
	}

	if (!cartItem.price || !cartItem.discountedPrice || !cartItem.discountedPriceReduction) {
		return null;
	}

	return (
		<Fragment>
			{hasDiscountCodeDiscount ? (
				<label className="text-sm italic text-success">
					{t('used-discount-code')}: {cart.discountCodeText}
				</label>
			) : (
				<label className="text-sm italic text-success">{courseversion.discountName}</label>
			)}
			<p className="font-bold text-success">
				-{formatPrice(cartItem.discountedPriceReduction, locale, 'EUR')}
			</p>
		</Fragment>
	);
};

export const CartItemPriceRow: React.FC<{
	cartItem: NonNullable<Flatten<OrderCartItems>>;
	courseversion: Courseversion;
	showQuantity?: boolean;
}> = ({ cartItem, courseversion, showQuantity }) => {
	const t = useTranslations('Cart');
	const locale = useLocale();
	const { isCartSyncInProgress, cart } = useCart();

	const title =
		typeof courseversion.course !== 'string' ? courseversion.course.title : courseversion.title;

	return (
		<div className="flex flex-col ">
			<div className="flex justify-between space-x-4">
				<p>{title}</p>

				<p className="font-bold">
					{formatPrice(cartItem.price! * cartItem.quantity, locale, 'EUR')}
				</p>
			</div>

			{showQuantity ? (
				<label className="text-sm italic text-gold-light">
					{dayjs(courseversion.timeEdit.startDate).format('DD.MM')} -{' '}
					{dayjs(courseversion.timeEdit.endDate).format('DD.MM')}
					{' | '}
					{t('slots', {
						slots: cartItem.quantity,
					})}
				</label>
			) : null}

			<div className="flex items-baseline justify-between space-x-4">
				<DiscountedRow courseversion={courseversion} cartItem={cartItem} />
			</div>
		</div>
	);
};

export const CartSummary: React.FC<{
	confirmToCheckout?: boolean;
	disclaimerText?: string;
	showQuantity?: boolean;
	showDiscountCodeInput?: boolean;
}> = props => {
	const { confirmToCheckout, disclaimerText, showQuantity, showDiscountCodeInput } = props;
	const t = useTranslations('Cart');
	const { user } = useAuth();
	const locale = useLocale();
	const router = useRouter();
	const [isPending, startTransition] = useTransition();
	const { cart, hasInitializedCart, isCartSyncInProgress } = useCart();
	const trackEvent = useTrackEvent();

	return (
		<Fragment>
			<h1 className="mb-6 text-4xl font-light text-muted-navy lg:mb-10 lg:text-5xl">
				{t('summary')}
			</h1>
			<div className="rounded-3xl bg-gold-transparent p-10">
				{!hasInitializedCart ? (
					<Skeleton className="flex h-[220px] w-full bg-transparent"></Skeleton>
				) : !cart?.cartItems?.length && hasInitializedCart ? (
					<p>{t('cartEmpty')}</p>
				) : (
					<Fragment>
						<div className="mb-10 space-y-5">
							{hasInitializedCart &&
								cart.cartItems?.map(cartItem => {
									if (
										!cartItem.orderedCollection ||
										typeof cartItem.orderedCollection.value === 'string' ||
										!cartItem.quantity
									) {
										return null;
									}

									const item = cartItem.orderedCollection.value;

									return (
										<Fragment key={`cartSummaryComponent-${item.id}`}>
											<CartItemPriceRow
												showQuantity={showQuantity}
												cartItem={cartItem}
												courseversion={item}
											/>
										</Fragment>
									);
								})}
						</div>

						{hasInitializedCart && showDiscountCodeInput && (
							<div>
								<DiscountCodeForm />
							</div>
						)}

						<CartTotal />

						{confirmToCheckout &&
							(!user ? (
								<AuthModal
									authType="login"
									trigger={
										<Button
											onClick={() => {
												trackEvent('checkout_clicked', {});
												trackEvent('login_or_registration_required', {});
											}}
											className="mt-10 w-full"
											intent="secondary"
										>
											{t('checkout')}
										</Button>
									}
									afterSubmit={() => {
										startTransition(() => {
											router.push('/checkout');
											trackEvent('authentication_completed', {});
										});
									}}
								></AuthModal>
							) : (
								<Button
									className="mt-10 w-full"
									intent="secondary"
									onClick={() => {
										startTransition(() => {
											router.push('/checkout');
											trackEvent('checkout_clicked', {});
										});
									}}
								>
									{t('checkout')}
								</Button>
							))}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};
